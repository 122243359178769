import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { getAlerts, shiftStatistics } from 'helpers/dashboard';
import { get } from 'lodash';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { Button, Collapse, UncontrolledTooltip } from 'reactstrap';
import RateBoostModal from 'scenes/Dashboard/V2/RateBoostModal/RateBoostModal';
import { boostRate } from 'redux/ducks/Dashboard';
import { deleteShift } from 'redux/ducks/Post/Shifts/actions';
import { rateTypeNameFinder } from 'helpers/rateTypeName';
import { formatTimeTz, formatTableDateTz } from 'helpers/datetime';
import { formatCurrency, SHOW_TOOLTIP_COLUMNS } from 'helpers/dashboard';
import { updateJob } from 'redux/ducks/Job';
import { initForm } from 'redux/ducks/Post/actions';
import JobsForShift from 'components/V2/JobsForShift';
import DeleteModal from 'components/V2/ConfirmationModal/DeleteModal';
import PaidJobModal from 'components/V2/PaidJobModal';
import { isAmericanExpress, getTimezone } from 'helpers/post';
import { max } from 'moment';
import { getEstimatedFee } from 'helpers/getEstimatedFee';

export const FILTER_JOBS = [
  'declined',
  'eo_removed_at',
  'cancelled',
  'withdrawn',
  'unapplied',
  'expired_no_at_confirm',
  'expired_no_eo_confirm',
];

const UpcomingShiftItem = (props) => {
  const [state, setState] = useState({
    isOpen: false,
    openDeleteModal: false,
  });
  const [isOverflowing, setIsOverflowing] = useState({});
  const { item, columns, idx, event, user, payment } = props;
  const tz = getTimezone(item.eventLocation);
  const isShiftPast = moment(item.endTime).isAfter(moment());
  const jobs = Object.values(get(item, 'jobs.byId', {}));
  const statistics = shiftStatistics(jobs);
  const filteredJobs = jobs.filter((job) => !FILTER_JOBS.includes(job.currentState));
  const columnRefs = useRef({});
  const resizeObserver = useRef(null);
  const [areRefsSet, setAreRefsSet] = useState(false);
  const confirmedJobs = jobs.filter((job) => job.currentState === 'confirmed');
  const maxEndTime = max(
    confirmedJobs.map((job) => moment(job.endTime)).concat(moment(item.endTime))
  );
  const isEnableCancelButton = maxEndTime.isAfter(moment());

  useEffect(() => {
    SHOW_TOOLTIP_COLUMNS.upcomingShifts.forEach((column) => {
      columnRefs.current[column] = React.createRef();
    });

    const checkOverflow = () => {
      const overflowing = {};
      SHOW_TOOLTIP_COLUMNS.upcomingShifts.forEach((column) => {
        overflowing[column] =
          columnRefs.current[column].current.scrollWidth >
          columnRefs.current[column].current.clientWidth;
      });
      setIsOverflowing({ ...overflowing });
    };
    resizeObserver.current = new ResizeObserver(checkOverflow);
    setAreRefsSet(true);

    if (!!props.shiftId && props.shiftId === item.id) {
      setState({ ...state, isOpen: true });
    }
  }, []);

  useEffect(() => {
    SHOW_TOOLTIP_COLUMNS.upcomingShifts.forEach((column) => {
      if (columnRefs?.current[column]?.current) {
        resizeObserver.current.observe(columnRefs.current[column].current);
      }
    });

    return () => {
      resizeObserver.current.disconnect();
    };
  }, [areRefsSet]);

  const hasPaidJobs = (jobs) => {
    return jobs.some((job) => job.currentState === 'paid');
  };

  const hasCheckedInJob = (jobs) => jobs.some((job) => ['checked_in'].includes(job.currentState));

  const pendingConfirmation = (shift) => {
    const { pendingConfirmation, capacityFilled } = shiftStatistics(jobs);

    if (moment(event.createdAt).isAfter(moment().subtract(48, 'hours'))) {
      if (capacityFilled === 0) return false;

      return !!pendingConfirmation;
    } else {
      return pendingConfirmation + capacityFilled >= shift.shiftCapacity;
    }
  };

  const boostedShift = (shift) => {
    return shift.boostedAt && moment(shift.boostedAt).isAfter(moment().subtract(48, 'hours'));
  };

  const handleAlert = () => {
    const statistics = shiftStatistics(jobs);
    const { capacityFilled, shiftRequests } = statistics;
    const shiftCapacity = get(item, 'shiftCapacity', 1);

    if (capacityFilled === shiftCapacity && shiftRequests > 0) return 'waitlist';

    if (capacityFilled === shiftCapacity) return 'filled';

    if (pendingConfirmation(item)) return 'confirmation';

    if (shiftRequests > 0) return 'review';

    if (moment(event.createdAt).isAfter(moment().subtract(48, 'hours'))) return 'new';

    if (boostedShift(item)) return 'boosted';

    return 'boost';
  };

  const getDate = () => {
    return formatTableDateTz(item.startTime, tz);
  };

  const getDateTime = () => {
    return `${formatTimeTz(item.startTime, tz)} - ${formatTimeTz(item.endTime, tz)}`;
  };

  const handleExpand = () => {
    setState((prevState) => ({
      ...prevState,
      isOpen: !state.isOpen,
    }));
  };

  const onEditShift = () => {
    props.initialize({
      ...event,
      shifts: Object.values(get(event, 'shifts.byId', {}), 'id'),
    });
    return props.history.push({
      pathname: `/v2/events/${props.match.params.eventId}/schedule-summary`,
      state: { shiftId: props.item.id },
    });
  };

  const toggleDeleteShiftModal = () => {
    setState((prevState) => ({
      ...prevState,
      openDeleteModal: !state.openDeleteModal,
    }));
  };

  const onDeleteShift = (idx, reason) => {
    props.deleteShift(props.item, props.event.id, reason);
    toggleDeleteShiftModal();
  };

  const showAlert = (shiftAlert, statistics) => {
    const buttonText =
      item.boostedAt && moment(item.boostedAt).isBefore(moment().subtract(48, 'hours'))
        ? 'BOOST AGAIN'
        : 'BOOST RATE';

    if (shiftAlert === 'review') {
      return (
        <div className="alert">
          <p className="m-0">{`${statistics.shiftRequests} APP${
            statistics.shiftRequests > 1 ? 'S' : ''
          }`}</p>
          <Button className="plain-button review-apps" onClick={handleExpand}>
            REVIEW APPS
          </Button>
        </div>
      );
    }

    if (shiftAlert === 'boost') {
      return (
        <div className="alert">
          <p className="m-0">NO APPS</p>
          <RateBoostModal
            shift={{ ...item, event }}
            onSave={props.boostRate}
            from="upcomingShifts"
            updateJob={props.updateJob}
          >
            {buttonText}
          </RateBoostModal>
        </div>
      );
    }
    return get(
      getAlerts({ isShift: true }).find((alert) => alert.key === shiftAlert),
      'name'
    );
  };

  const computeTopSpaceForAtsColumn = (idx) => {
    return idx === 0 ? '-12.5px' : '-11.5px';
  };

  const computeTopHeightForAtsColumn = (idx) => {
    return idx === 0 ? '45px' : '43px';
  };

  const shiftAlert = handleAlert();

  const fee = getEstimatedFee(moment(item.startTime), 0, isAmericanExpress(payment));
  const shiftSum = item.shiftSum + fee * item.shiftSum * 0.01;

  return (
    <React.Fragment>
      <div
        className={classNames('upcoming-shift-item', {
          'collapse-border-bottom': !state.isOpen,
          'long-table': props.match.params.id === 'all',
        })}
      >
        <div className={`${columns['date'].size} upcoming-shifts-row`}>{getDate()}</div>
        <div
          className={classNames(`${columns['dateTime'].size} upcoming-shifts-row`, {
            'job-title': state.isOpen && props.match.params.id !== 'all',
            'customTimeWidth': props.match.params.id === 'all',
          })}
        >
          {getDateTime()}
        </div>
        {props.match.params.id === 'all' && (
          <div
            className={classNames(`${columns['location'].size} upcoming-shifts-row`, {
              'job-title': state.isOpen,
            })}
            id={`location-${item.id}`}
            ref={columnRefs?.current['location']}
          >
            {item.eventLocation.name}
            {isOverflowing.location && (
              <UncontrolledTooltip target={`location-${item.id}`}>
                {item.eventLocation.name}
              </UncontrolledTooltip>
            )}
          </div>
        )}
        <div
          className={classNames(`${columns['ATs'].size} upcoming-shifts-row p-0 ats-container`, {
            'grey-expand': filteredJobs.length === 0,
            'expand': filteredJobs.length !== 0,
            'ats-margin': state.isOpen,
          })}
          onClick={filteredJobs.length !== 0 ? handleExpand : () => {}}
        >
          <div
            className={classNames('ats-section')}
            style={{
              top: computeTopSpaceForAtsColumn(idx),
              height: computeTopHeightForAtsColumn(idx),
            }}
          >
            <p
              className={classNames(`ats m-0`, {
                'open-collapse': state.isOpen,
              })}
            >
              ATs
              {state.isOpen && <i className="material-icons">expand_more</i>}
              {!state.isOpen && <i className="material-icons">chevron_right</i>}
            </p>
          </div>
        </div>
        <div className={classNames(`${columns['filled'].size} upcoming-shifts-row`)}>
          {statistics.capacityFilled}/{item.shiftCapacity}
        </div>
        <div
          className={classNames(`${columns['alert'].size} alerts upcoming-shifts-row`, {
            [shiftAlert]: moment(item.endTime).isAfter(moment()),
            customAlertWidth: props.match.params.id === 'all',
          })}
        >
          {isShiftPast && showAlert(shiftAlert, statistics)}
        </div>
        <div
          className={classNames(`${columns['shiftSum'].size} upcoming-shifts-row`)}
          style={{ paddingRight: 0 }}
        >
          {item.shiftSum
            ? `${formatCurrency({
                currency: shiftSum,
                dollarSign: true,
              })}
          `
            : '-'}
        </div>
        <div
          className={classNames(`${columns['actions'].size} shift-actions`, {
            'flex-end': !isShiftPast,
          })}
        >
          {isShiftPast && (
            <button className={classNames('plain-button edit-button')} onClick={onEditShift}>
              EDIT
            </button>
          )}
          {isEnableCancelButton && (
            <React.Fragment>
              {!hasCheckedInJob(jobs) &&
                (hasPaidJobs(jobs) ? (
                  <PaidJobModal key={item.id}>
                    <span className="plain-button delete-button">CANCEL</span>
                  </PaidJobModal>
                ) : (
                  <button
                    className={classNames('plain-button delete-button')}
                    onClick={toggleDeleteShiftModal}
                  >
                    CANCEL
                  </button>
                ))}
            </React.Fragment>
          )}
        </div>
        <DeleteModal
          key={item.idx}
          isOpen={state.openDeleteModal}
          title="Cancel Shift"
          body="Canceling this shift will remove athletic trainers who applied or confirmed to work."
          cancelText="Cancel Shift"
          toggle={toggleDeleteShiftModal}
          onDelete={onDeleteShift}
          item={item}
          user={user}
          eventName={event.title}
          isAmex={isAmericanExpress(props.payment)}
          rateTypes={props.rateTypes}
        />
      </div>
      <Collapse
        isOpen={state.isOpen}
        style={{
          width: '100%',
          textAlign: 'left',
        }}
      >
        <JobsForShift
          jobs={filteredJobs}
          shift={item}
          from="upcoming"
          handleExpand={handleExpand}
          isOpen={state.isOpen}
          eventTitle={event.title}
        />
      </Collapse>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.session.currentUser,
  payment: state.payment,
  rateTypes: state.enums.rateTypes,
});

const mapDispatchToProps = (dispatch) => ({
  boostRate: (payload) => dispatch(boostRate(payload)),
  deleteShift: (shift, eventId, reason) => dispatch(deleteShift({ shift, eventId, reason })),
  updateJob: (job) => dispatch(updateJob(job)),
  initialize: (event) => dispatch(initForm(event)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpcomingShiftItem));
